import React, { Dispatch, SetStateAction, useEffect } from "react";
// Utils
import { emailRegex } from "../utils/regex";
// Components
import AddressAutocomplete from "../components/addressAutocomplete";
// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// MUI
import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Grid from "@mui/material/Grid";

const requiredMessage = "This field is required";

const userSchema = yup.object({
  firstName: yup.string().required(requiredMessage),
  lastName: yup.string().required(requiredMessage),
  email: yup
    .string()
    .matches(emailRegex, "Courriel invalide")
    .required(requiredMessage),
  address: yup.string(),
  phone: yup.string(),
  password: yup.string(),
});

interface Props {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<any[]>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
}

const PersonalInfo: React.FC<Props> = ({
  date,
  setDate,
  name,
  setName,
  lastName,
  setLastName,
  email,
  setEmail,
  address,
  setAddress,
  phone,
  setPhone,
}) => {
  //SET DATE UP FOR DEFAULT VALUE

  const {
    formState: { errors },
    control,
    setValue,
  } = useForm({ resolver: yupResolver(userSchema) });

  useEffect(() => {
    setValue("firstName", name);
    setValue("lastName", lastName);
    setValue("email", email);
    setValue("address", address);
    setValue("phone", phone);
  }, []);

  return (
    <div>
      <h2 className="title">Personal information</h2>
      <p className="text">
        Welcome to CryptoCredit, we are glad to see you! Let's get started by
        letting us know a little bit about you.
      </p>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                fullWidth
                label="First name"
                variant="standard"
                {...field}
                onChange={(e) => {
                  setName(e.target.value);
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
                label="Last name"
                variant="standard"
                {...field}
                onChange={(e) => {
                  setLastName(e.target.value);
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <br />

      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            error={!!errors.email}
            helperText={errors.email?.message}
            fullWidth
            label="Email"
            variant="standard"
            {...field}
            onChange={(e) => {
              setEmail(e.target.value);
              field.onChange(e.target.value);
            }}
          />
        )}
      />

      <br />
      <br />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <MuiPhoneNumber
                label="Phone Number"
                error={!!errors.phone}
                helperText={errors.phone?.message}
                defaultCountry={"us"}
                fullWidth
                {...field}
                onChange={(e) => {
                  setPhone(e as string);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date of Birth"
              value={date}
              onChange={(newValue: any) => {
                setDate(newValue ? newValue : "");
              }}
              renderInput={(params: any) => (
                <TextField
                  variant="standard"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <br />
      <br />
      <br />

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <AddressAutocomplete
            setAddress={setAddress}
            address={address}
            onChange={(val) => {
              field.onChange(val);
            }}
          />
        )}
      />
    </div>
  );
};

export default PersonalInfo;
