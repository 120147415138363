// React
import React from "react";

// Mui
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

//assets
import Error from "../assets/error404.png";

const Unauthorized: React.FC = () => {
  return (
    <div>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h4"
          align="left"
          color="textPrimary"
          gutterBottom
          className="margin__none"
        >
          Error 404
        </Typography>
        <Typography
          variant="h5"
          align="left"
          color="textPrimary"
          paragraph
          className="margin__none"
        >
          Page not found
        </Typography>
        <img src={Error} alt="error" />
      </Container>
    </div>
  );
};

export default Unauthorized;
