import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
// // Interfaces
// import { User } from "cc-interfaces";
// Firebase
import { auth, firestore } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const initUser = { currentUser: null, fetchingUser: true };

const useFetchUser = () => {
  //under currentUser, add User | null as the type, not just null
  const [currentUser, setCurrentUser] = useState<any>(initUser.currentUser);
  const [fetchingUser, setFetchingUser] = useState<boolean>(
    initUser.fetchingUser
  );

  useEffect(() => {
    let unsub: any;
    auth.onAuthStateChanged(async (res) => {
      if (res) {
        setFetchingUser(true);
        unsub = onSnapshot(doc(firestore, "Users", res.uid), (doc) => {
          setCurrentUser(doc.data());
          setFetchingUser(false);
        });
      } else {
        setCurrentUser(null);
        setFetchingUser(false);
      }
    });
    return () => unsub();
  }, []);

  return { currentUser, fetchingUser };
};

export const useFetchUserSingleton = singletonHook(initUser, useFetchUser);
