import React, { SetStateAction, Dispatch, useEffect, useRef } from "react";

//MUI
import Input from "@mui/material/Input";

interface Props {
  setAddress: Dispatch<SetStateAction<any[]>>;
  address: string;
  onChange?: (val: any) => void;
}
const AddressAutocomplete = ({ setAddress, address, onChange }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  let autocomplete: google.maps.places.Autocomplete;

  useEffect(() => {
    initAutocomplete();
  }, []);

  const initAutocomplete = () => {
    if (ref.current) {
      autocomplete = new google.maps.places.Autocomplete(ref.current, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components"],
        types: ["address"],
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setAddress(place.address_components ?? []);
        if (onChange) onChange(ref.current ? ref.current.value : "");
      });
    }
  };

  return (
    <div>
      <Input
        fullWidth
        inputRef={ref}
        placeholder="Address"
        defaultValue={address}
        name="noAutoFill"
        autoComplete="off"
      />
    </div>
  );
};

export default AddressAutocomplete;
