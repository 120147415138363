import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useSnackbar } from "notistack";
//assets
import logo from "../assets/logo.svg";
import arrow from "../assets/arrow.svg";
import loans from "../assets/loans.svg";
import account from "../assets/account.svg";
import help from "../assets/help.svg";
import logout from "../assets/logout.svg";

//firebase
import { auth } from "../firebase/firebase";

//Custom Components
import { useFetchUserSingleton } from "../hooks/useFetchUser";

const Sidebar: React.FC = () => {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useFetchUserSingleton();

  const handleLogout = async () => {
    await auth.signOut();
  };

  const handleApply = () => {
    navigate("/loan-application");
    // currentUser.sumsubStatus.reviewAnswer === "GREEN"
    //   ? navigate("/loan-application")
    //   : enqueueSnackbar("Your account must be verified to apply for a loan", {
    //       variant: "error",
    //     });
  };

  return (
    <div className="sidebar">
      <div className="sidebar__container">
        <img className="sidebar__logo" src={logo} alt="" />
        <div className="sidebar__nav__container">
          <NavLink to="/loan-application">
            <img src={loans} /> Loan Application
          </NavLink>
          {/* <NavLink to="/account">
            <img src={account} /> Account
          </NavLink> */}
          <NavLink to="/help">
            <img src={help} /> Help
          </NavLink>
        </div>
        <div className="sidebar__nav__bottom">
          {/* <div onClick={handleApply} className="button-light">
            Apply for a new Loan <img src={arrow} />
          </div> */}
          {/* <div onClick={handleLogout} className="sidebar__logout">
            <img src={logout} /> Logout
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
