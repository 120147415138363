import { useEffect, useState, useRef } from "react";

const useFetchCryptoPrice = () => {
  //states
  const [BTCPrice, setBTCPrice] = useState("");
  const [ETHPrice, setETHPrice] = useState("");
  const [BNBPrice, setBNBPrice] = useState("");
  //ref
  const BTCCurrentPrice = useRef(0);
  const BTCPreviousPrice = useRef(0);
  const ETHPreviousPrice = useRef(0);
  const ETHCurrentPrice = useRef(0);
  const BNBCurrentPrice = useRef(0);
  const BNBPreviousPrice = useRef(0);

  useEffect(() => {
    let socketArray = [];
    // WEBHOOKS
    //prettier-ignore
    const BTCWebsocket = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@ticker");
    socketArray.push(BTCWebsocket);
    //prettier-ignore
    const ETHWebsocket = new WebSocket("wss://stream.binance.com:9443/ws/ethusdt@ticker");
    socketArray.push(ETHWebsocket);
    //prettier-ignore
    const BNBWebsocket = new WebSocket("wss://stream.binance.com:9443/ws/bnbusdt@ticker");
    socketArray.push(BNBWebsocket);

    socketArray.forEach((socket) => {
      socket.onmessage = (e: any) => {
        const socketObj = JSON.parse(e.data);
        const currentPrice = parseFloat(socketObj.c);
        if (socket.url.substring(33, 36) === "btc") {
          BTCPreviousPrice.current = BTCCurrentPrice.current;
          BTCCurrentPrice.current = currentPrice;
          setBTCPrice(currentPrice.toFixed(2));
        }
        if (socket.url.substring(33, 36) === "eth") {
          ETHPreviousPrice.current = ETHCurrentPrice.current;
          ETHCurrentPrice.current = currentPrice;
          setETHPrice(currentPrice.toFixed(2));
        }
        if (socket.url.substring(33, 36) === "bnb") {
          BNBPreviousPrice.current = BNBCurrentPrice.current;
          BNBCurrentPrice.current = currentPrice;
          setBNBPrice(currentPrice.toFixed(2));
        }
      };
    });

    return () => {
      BTCWebsocket.close();
      ETHWebsocket.close();
      BNBWebsocket.close();
    };
  }, []);

  const selectCryptocurrency = (cryptocurrency: string) => {
    switch (cryptocurrency) {
      case "BTC":
        return parseFloat(BTCPrice);

      case "ETH":
        return parseFloat(ETHPrice);

      case "BNB":
        return parseFloat(BNBPrice);
    }
  };

  return {
    selectCryptocurrency,
    BTCPrice,
    ETHPrice,
    BNBPrice,
    BTCCurrentPrice,
    BTCPreviousPrice,
    ETHCurrentPrice,
    ETHPreviousPrice,
    BNBCurrentPrice,
    BNBPreviousPrice,
  };
};

export default useFetchCryptoPrice;
