import React, { useState, useEffect } from "react";
// Hooks
import { useNavigate } from "react-router-dom";
import { useFetchUserSingleton } from "../../hooks/useFetchUser";
// Validation
import { useSnackbar } from "notistack";
// Interfaces
import { User } from "../../interfaces";
// Firebase
import { functions, firestore, auth } from "../../firebase/firebase";
import { doc, setDoc, collection } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
// Custom Components
import PersonalInfo from "../../components/PersonalInfo";
import Password from "../../components/Password";
import FormButtons from "../../components/FormButtons";
// Assets
import logo from "../../assets/logo.svg";
import personalInfoPicture from "../../assets/personalInfoPicture.svg";
import passwordPicture from "../../assets/passwordPicture.svg";
import confirmationPicture from "../../assets/confirmationPicture.svg";
import { HiArrowNarrowRight } from "react-icons/hi";
//  MUI
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

interface AddressFields {
  [key: string]: string;
}

const steps = [
  {
    label: "Personal information",
  },
  {
    label: "Account details",
  },
  {
    label: "Confirmation",
  },
];

const OnboardingPage: React.FC = () => {
  //Router-Dom -----> useHistory got updated to useNavigate in v6.0
  const navigate = useNavigate();
  const { currentUser } = useFetchUserSingleton();
  //Hooks
  const { enqueueSnackbar } = useSnackbar();
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [date, setDate] = useState(new Date().toDateString());
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [formatedAddress, setFormatedAddress] = useState<AddressFields>({});
  const [unformatedAddress, setUnformatedAddress] = useState<any[]>([]);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  // Functions

  const formatAddress = (): AddressFields => {
    const address: AddressFields = {};
    if (unformatedAddress[0].types.includes("street_number")) {
      unformatedAddress.forEach((dataPoint) => {
        address[dataPoint.types[0]] = dataPoint.long_name;
      });
      setFormatedAddress(address);
      return address;
    } else return {} as AddressFields;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!personalInfoIsValid()) return;

    //Create user with authentication, for now autogenerate password
    try {
      const password = Math.random().toString(36).slice(-8);
      const authResponse = await auth.createUserWithEmailAndPassword(email, password);
      const user: User = {
        lastLogin: new Date(),
        firstName: name,
        lastName,
        email,
        phone,
        dateOfBirth: new Date(date),
        address: formatedAddress,
        createdAt: new Date(),
        updatedAt: new Date(),
        id: authResponse.user?.uid,
        sumsubStatus: { sumsubId: "", reviewStatus: "" },
        roles: [],
      };

      const collectionRef = collection(firestore, "Users");
      await setDoc(doc(collectionRef, user.id), user);
      navigate("/loan-application");

      const sendEmail = httpsCallable(functions, "dashboard-sendEmail");
      sendEmail({
        emailIntent: "welcome",
        to: email,
        subject: "Welcome aboard! ✨",
        firstName: name,
      });
    } catch (e: any) {
      switch (e.code) {
        case "auth/weak-password":
          enqueueSnackbar("Password is too weak", { variant: "error" });
          break;
        default:
          console.log(e);
          enqueueSnackbar("There was an issue, please try again later", {
            variant: "error",
          });
      }
    }
  };

  const personalInfoIsValid = (): boolean => {
    if (!verifyAge()) {
      enqueueSnackbar("You do not meet the minimum age requirement for a loan", {
        variant: "error",
      });
      return false;
    }
    if (name === "" || lastName === "" || email === "" || phone === "" || unformatedAddress.length === 0) {
      enqueueSnackbar("Please complete all of the fields. Be sure to select a valid Google address", {
        variant: "error",
      });
      return false;
    } else if (Object.keys(formatAddress()).length === 0) {
      enqueueSnackbar("Address requires a valid street number", {
        variant: "error",
      });
      return false;
    } else {
      return true;
    }
  };

  const verifyAge = (): boolean => {
    const today = new Date();
    const birthDate = new Date(date);
    if (today.getFullYear() - 18 > birthDate.getFullYear()) return true;
    else if (today.getFullYear() - 18 === birthDate.getFullYear()) {
      if ((today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate()) || today.getMonth() > birthDate.getMonth()) return true;
    }
    return false;
  };

  //format address received from google for firebase
  useEffect(() => {
    if (unformatedAddress.length) formatAddress();
  }, [unformatedAddress]);

  //format address received from google for firebase

  return (
    <div className="onboarding">
      <div className="onboarding__container">
        <div className="onboarding__left">
          <img src={logo} className="image" alt="logo" />
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <img src={personalInfoPicture} className="onboarding__left__image" alt="personalInfoPicture" />
          ) : activeStep === 1 ? (
            <img src={passwordPicture} className="onboarding__left__image" alt="passwordPicture" />
          ) : (
            <img src={confirmationPicture} className="onboarding__left__image" alt="confirmationPicture" />
          )}
        </div>
        <div className="onboarding__right">
          <div className="onboarding__right__top">
            <span>STEP {activeStep + 1} OF 3</span>
            <span>
              Lost or having trouble?{" "}
              <a href="https://crypto-credit.com/contact/" target="_blank" rel="noreferrer">
                <span className="onboarding__right__top__help">
                  Get help &nbsp; <HiArrowNarrowRight />
                </span>
              </a>
            </span>
          </div>
          <form onSubmit={onSubmit} className="onboarding__right__steps">
            {activeStep === 0 && (
              <>
                <PersonalInfo
                  date={date}
                  setDate={setDate}
                  name={name}
                  setName={setName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  address={Object.keys(formatedAddress)
                    .map((key) => {
                      return formatedAddress[key];
                    })
                    .join(" ")}
                  setAddress={setUnformatedAddress}
                  phone={phone}
                  setPhone={setPhone}
                />
              </>
            )}
            {/* {activeStep === 1 && (
              <>
                <Password password={password} setPassword={setPassword} confirmPassword={confirmedPassword} setConfirmPassword={setConfirmedPassword} />
              </>
            )} */}
            {/* {activeStep === 2 && (
              <div>
                <h2 className="title">Confirmation</h2>
                <p className="text">Your account is almost created. Please read and accept the Terms of Service to start using CryptoCredit!</p>
                <h4 className="title">Do you accept our Terms of Service?</h4>
                <p className="text">We know you care about how your personal information is used and shared, so we take your privacy seriously. Please take the time to review our ToS</p>
                <div className="onboarding__tos__radio">
                  <RadioGroup onChange={handleChange} value={verified} row>
                    <FormControlLabel value="true" control={<Radio checked={verified} />} label="Accept" />
                    <FormControlLabel value="false" control={<Radio checked={!verified} />} label="Deny" />
                  </RadioGroup>
                </div>
                <span className="onboarding__tos__cta" onClick={() => setOpenModal(true)}>
                  Expand our ToS &nbsp; <HiArrowNarrowRight />
                </span>
                {openModal && (
                  <Dialog open={openModal} onClose={() => setOpenModal(false)} className="onboarding__tos__dialog">
                    <h3 className="title">Please take the time to review our ToS</h3>
                    <p className="text">
                      At Crypto Credit, accessible from www.crypto-credit.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information
                      that is collected and recorded by Crypto Credit and how we use it.
                      <br />
                      <br />
                      If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                      <br />
                      <br />
                      This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Crypto
                      Credit. This policy is not applicable to any information collected offline or via channels other than this website.
                      <br />
                      <br />
                      <b>Consent</b>
                      <br />
                      By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                      <br />
                      <br />
                      <b>Information we collect</b>
                      <br />
                      The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your
                      personal information.
                      <br />
                      <br />
                      If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may
                      send us, and any other information you may choose to provide.
                      <br />
                      <br />
                      When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                      <br />
                      <br />
                      <b>How we use your information</b>
                      <br />
                      We use the information we collect in various ways, including to:
                      <br />
                      <br />
                      - Provide, operate, and maintain our website
                      <br />
                      - Improve, personalize, and expand our website
                      <br />
                      - Understand and analyze how you use our website
                      <br />
                      - Develop new products, services, features, and functionality
                      <br />
                      - Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website,
                      and for marketing and promotional purposes
                      <br />
                      - Send you emails
                      <br />
                      - Find and prevent fraud
                      <br />
                      <br />
                      <b>Log Files</b>
                      <br />
                      Crypto Credit follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services'
                      analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit
                      pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends,
                      administering the site, tracking users' movement on the website, and gathering demographic information.
                      <br />
                      <br />
                      <b>Cookies and Web Beacons</b>
                      <br />
                      Like any other website, Crypto Credit uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor
                      accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
                      <br />
                      <br />
                      <b>Google DoubleClick DART Cookie</b>
                      <br />
                      Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and
                      other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL –
                      https://policies.google.com/technologies/ads
                      <br />
                      <br />
                      <b>Advertising Partners Privacy Policies</b>
                      <br />
                      You may consult this list to find the Privacy Policy for each of the advertising partners of Crypto Credit.
                      <br />
                      <br />
                      Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Crypto
                      Credit, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their
                      advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                      <br />
                      <br />
                      Note that Crypto Credit has no access to or control over these cookies that are used by third-party advertisers.
                      <br />
                      <br />
                      <b>Third Party Privacy Policies</b>
                      <br />
                      Crypto Credit's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad
                      servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                      <br />
                      <br />
                      You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found
                      at the browsers' respective websites.
                      <br />
                      <br />
                      <b>CCPA Privacy Rights (Do Not Sell My Personal Information)</b>
                      <br />
                      <br />
                      Under the CCPA, among other rights, California consumers have the right to:
                      <br />
                      <br />
                      Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                      <br />
                      <br />
                      Request that a business delete any personal data about the consumer that a business has collected.
                      <br />
                      <br />
                      Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
                      <br />
                      <br />
                      If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                      <br />
                      <br />
                      GDPR Data Protection Rights
                      <br />
                      We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                      <br />
                      <br />
                      The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                      <br />
                      <br />
                      The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the
                      information you believe is incomplete.
                      <br />
                      <br />
                      The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                      <br />
                      <br />
                      The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                      <br />
                      <br />
                      The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                      <br />
                      <br />
                      The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain
                      conditions.
                      <br />
                      <br />
                      If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                      <br />
                      <br />
                      <b>Children's Information</b>
                      <br />
                      Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide
                      their online activity.
                      <br />
                      <br />
                      Crypto Credit does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information
                      on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>
                  </Dialog>
                )}
              </div>
            )} */}
            <div className="onboarding__controls">
              {/* <FormButtons onSubmit={onSubmit} passwordIsValid={passwordIsValid} personalInfoIsValid={personalInfoIsValid} setActiveStep={setActiveStep} activeStep={activeStep} /> */}
              <button onClick={onSubmit} className="onboarding__controls__next button-dark">
                <HiArrowNarrowRight /> &nbsp; Next Step
              </button>
              {/* {activeStep === 1 && (
                <button type="submit" disabled={!verified} className="onboarding__controls__next button-dark">
                  Create account &nbsp; <HiArrowNarrowRight />
                </button>
              )} */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
