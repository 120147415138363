import React, { createRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
//MUI
import Button from "@mui/material/Button";
//Custom Components
import Router from "./routes/router";

function App() {
  const snackbarRef: any = createRef();
  return (
    <SnackbarProvider
      ref={snackbarRef}
      hideIconVariant
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={4000}
      action={(key) => (
        <Button
          style={{ color: "white" }}
          onClick={() => snackbarRef.current.closeSnackbar(key)}
        >
          CLOSE
        </Button>
      )}
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
