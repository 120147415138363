import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { MdExpandMore } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
// MUI
import { useFetchUserSingleton } from "../hooks/useFetchUser";
// Assets
import background from "../assets/background.svg";
// Firebase
import { auth } from "../firebase/firebase";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/firebase";
// MUI
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Help: React.FC = () => {
  const { currentUser } = useFetchUserSingleton();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleHelp = async (e: any) => {
    e.preventDefault();
    console.log("sending to firebase");
    await httpsCallable(
      functions,
      "dashboard-sendEmail"
    )({
      to: "no-reply@crypto-credit.com",
      from: currentUser.email,
      text: `${currentUser.name} ${currentUser.lastName} requests help.`,
      subject: `${subject}`,
      html: `<div>${message}</div>`,
    }).then(() => {
      setSubject("");
      setMessage("");
      enqueueSnackbar("Your support request has been received. We typically respond within 1-2 business days", {
        variant: "success",
      });
    });
  };

  // const handleChangePassword = () => {
  //   try {
  //     auth.sendPasswordResetEmail(currentUser.email).then(() => {
  //       enqueueSnackbar("A reset password email has been sent", {
  //         variant: "success",
  //       });
  //     });
  //   } catch (e) {
  //     console.log(e);
  //     enqueueSnackbar(
  //       "There was an error processing the request, please try again.",
  //       { variant: "error" }
  //     );
  //   }
  // };

  useEffect(() => {
    if (!currentUser?.id) navigate("/");
  }, []);

  return (
    <div className="help">
      <img src={background} className="help__bg" alt="background" />
      <h2 className="title">Help</h2>
      <p className="text">Support at your fingertips</p>
      <Grid container spacing={2} className="help__grid">
        <Grid item sm={12} md={6}>
          <form onSubmit={handleHelp}>
            <TextField fullWidth label="Subject" variant="standard" onChange={(e) => setSubject(e.target.value)} value={subject} />
            <TextField fullWidth label="Message" variant="standard" onChange={(e) => setMessage(e.target.value)} multiline rows={6} value={message} />
            <button type="submit" className="button-dark">
              Send email
            </button>
          </form>

          {/* <div className="help__reset">
            <h3 className="title">Change your password?</h3>
            <p className="text">
              If you think your password has been compromised or might have been
              exposed, please change it. Never share your password with anyone.
            </p>
            <div className="button-semi" onClick={handleChangePassword}>
              Reset my password
            </div>
          </div> */}
        </Grid>
        <Grid item sm={12} md={6}>
          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>What is Crypto Credit?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>We are the first platform that will allow you to buy selected crypto currencies today, and repay them in instalments.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography>How does it work?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The process is very simple. First you have to create a user account. Once that part is done, you have to complete the KYC process ( Know-your-customers). You will then be verified
                within 24-48 Hours. Once verified, you can apply for a guaranteed loan, choose the amount, the cryptocurrency of your choice, choose a method of payment and then follow the steps to
                finalize the crypto loan.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel3a-content" id="panel3a-header">
              <Typography>How long is the term of the loan?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Typically a loan will be reimbursed within 12 easy weekly instalments. A down payment will be required in order to get approved.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel4a-content" id="panel4a-header">
              <Typography>What happens if I miss a payment?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you miss a payment, a reminder email will be send to you and you will have 24 hrs to make the missed payment. Beyond that deadline, your crypto assets might be liquidated to repay
                your balance due. Please borrow within your means.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel5a-content" id="panel5a-header">
              <Typography>What happens after a loan is repaid?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Once a balance is cleared, you will be prompted to enter your digital wallet and your cryptos will be sent to you automatically.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel5a-content" id="panel5a-header">
              <Typography>Can I borrow more than one loan at a time?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>You can borrow as much and as often as you would like. However we encourage you to borrow within your means.</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default Help;
