import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const BaseLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="baseLayout__container">
        <main>
          <div className="baseLayout__toolbar" />
          {children}
        </main>
      </div>
    </>
  );
};

export default BaseLayout;
