import React, { useEffect } from "react";
// Hooks
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useFetchUserSingleton } from "../hooks/useFetchUser";
//  Custom Components
import OnboardingPage from "../pages/login/OnboardingPage";
import Loans from "../pages/Loans";
import Login from "../pages/login/Login";
import Account from "../pages/Account";
import Help from "../pages/Help";
import LoanApplication from "../pages/loan/LoanApplication";
import Loan from "../pages/loan/Loan";
import Unauthorized from "../components/Unauthorized";
import BaseLayout from "../components/layout/BaseLayout";
import MainLayout from "../components/layout/MainLayout";

//assets
import loadingGif from "../assets/ccLoading.gif";

//types
type RouteProps = {
  path: string;
  Component: React.FC;
  Private: boolean;
  LayoutComponent: React.FC<any>;
};
const routes: RouteProps[] = [
  {
    path: "/",
    Component: OnboardingPage,
    Private: false,
    LayoutComponent: BaseLayout,
  },
  {
    path: "/help",
    Component: Help,
    Private: true,
    LayoutComponent: MainLayout,
  },
  {
    path: "/loan-application",
    Component: LoanApplication,
    Private: true,
    LayoutComponent: MainLayout,
  },
  // {
  //   path: "/loans",
  //   Component: Loans,
  //   Private: true,
  //   LayoutComponent: MainLayout,
  // },
  // {
  //   path: "/account",
  //   Component: Account,
  //   Private: true,
  //   LayoutComponent: MainLayout,
  // },
  // {
  //   path: "/loan/:id",
  //   Component: Loan,
  //   Private: true,
  //   LayoutComponent: MainLayout,
  // },
];

const Router: React.FC = () => {
  const { currentUser, fetchingUser } = useFetchUserSingleton();
  let location = useLocation();

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    if (!currentUser) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
  };

  if (!fetchingUser)
    return (
      <Routes>
        {routes.map(({ path, Component, Private, LayoutComponent }: RouteProps, i) => {
          if (Private) {
            return (
              <Route
                path={path}
                element={
                  <RequireAuth>
                    <LayoutComponent>
                      <Component />
                    </LayoutComponent>
                  </RequireAuth>
                }
                key={i}
              />
            );
          } else {
            return (
              <Route
                path={path}
                element={
                  <LayoutComponent>
                    <Component />
                  </LayoutComponent>
                }
                key={i}
              />
            );
          }
        })}
        <Route path="*" element={<Unauthorized />} />
      </Routes>
    );
  else {
    return (
      <div className="loading__container">
        <img src={loadingGif} alt="loading" />
      </div>
    );
  }
};

export default Router;
