import serviceConfig from "./firebaseConfig.json";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/functions";

firebase.initializeApp(serviceConfig);

import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

//export const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);
