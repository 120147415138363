import React, { useState, useRef, useEffect } from "react";
// Validation
import { useSnackbar } from "notistack";
// Assets
import loadingGif from "../../assets/ccLoading.gif";
// Custom Components
import { CreditCardPaymentForm } from "../../components/CreditCardPaymentForm";
import { EFTPaymentForm } from "../../components/EFTPaymentForm";
// NPM
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
// Firebase
import { httpsCallable } from "firebase/functions";
import { auth, firestore, functions } from "../../firebase/firebase";
// UI
import { MdEmail } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { HiArrowNarrowRight } from "react-icons/hi";
// Hooks
import { useNavigate } from "react-router-dom";
import { useFetchUserSingleton } from "../../hooks/useFetchUser";
import useFetchCryptoPrice from "../../hooks/useFetchCryptoPrice";
import useVerifySumsub from "../../hooks/useVerifySumsub";
// MUI
import Select from "@mui/material/Select";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import StepButton from "@mui/material/StepButton";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";

const steps = ["Loan details", "Payment Type", "KYC"];

const LoanApplication: React.FC = () => {
  //Sumsub
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isSumsubApplicantSubmitted, setIsSumsubApplicantSubmitted] = useState(false);

  // Hooks
  const navigate = useNavigate();
  const { currentUser } = useFetchUserSingleton();
  const { handleSumsub } = useVerifySumsub(setOpenDialog, currentUser?.id || "", setIsSumsubApplicantSubmitted);

  const sigPad = useRef<any>({});
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const [loanAmount, setLoanAmount] = useState("");
  const [coin, setCoin] = useState("");
  const [openContract, setOpenContract] = useState(false);
  const [signContract, setSignContract] = useState(false);
  const [sigPadString, setSigPadString] = useState("");
  const [ip, setIp] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);

  // PAYMENT FORM
  const [paymentType, setPaymentType] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cardInputValue, setCardInputValue] = useState("");
  const [cardNums, setCardNums] = useState("");
  const [institutionNumber, setInstitutionNumber] = useState("");
  const [transitNumber, setTransitNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const term = "3 Months";
  const frequency = "weekly";

  const { BTCPrice, ETHPrice, BNBPrice } = useFetchCryptoPrice();
  const [currentCoinPrice, setCurrentCoinPrice] = useState(0);

  useEffect(() => {
    const fetchIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
    };
    fetchIp();
  }, []);

  useEffect(() => {
    if (openContract) {
      switch (coin) {
        case "BTC":
          setCurrentCoinPrice(parseFloat(BTCPrice));
          break;
        case "ETH":
          setCurrentCoinPrice(parseFloat(ETHPrice));
          break;
        case "BNB":
          setCurrentCoinPrice(parseFloat(BNBPrice));
          break;
      }
    }
  }, [openContract]);

  //Create subscription and loan once sumsub is completed
  useEffect(() => {
    if (isSumsubApplicantSubmitted) submitLoan();
  }, [isSumsubApplicantSubmitted]);
  console.log(isSumsubApplicantSubmitted);

  const coinQuantityCalc = () => {
    const qty = Math.round((parseInt(loanAmount) / currentCoinPrice) * 100000) / 100000;
    return qty;
  };

  const firstPaymentCalc = () => {
    const qty = Math.round(parseInt(loanAmount) * 100 * 0.13) / 100;
    return qty;
  };

  const handleStep = (i: number) => {
    setActiveStep(i);
  };

  const submitLoan = async () => {
    setPaymentLoading(true);
    //prettier-ignore
    const createSubscription = httpsCallable(functions, "binanceDisabled-createSubscription")
    const response: any = await createSubscription(
      JSON.stringify({
        firebaseId: currentUser.id,
        //User info
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        //Credit Card Info
        creditCardNumber: cardNums.toString(),
        expiryMonth: expiry.substring(0, 2),
        expiryYear: new Date().getFullYear().toString().substring(0, 2) + expiry.substring(3),
        cvv: cvc,
        //EFT info
        institutionNumber,
        transitNumber,
        accountNumber,
        //Address info
        street: currentUser.address.route,
        city: currentUser.address.Locality,
        province: "QC",
        country: currentUser.address.country,
        postalCode: currentUser.address.postal_code,
        //currentUser.address.administrative_area_level_1, ?????
        //Loan info
        loanAmount,
        cryptocurrency: coin,
        ip,
        signature: sigPadString,
      })
    );
    if (response.data.statusCode !== 200) {
      //prettier-ignore
      enqueueSnackbar("An ERROR has occured, please try again",{ variant: "error" });
      navigate(`/loan-application`);
    } else {
      enqueueSnackbar(`Successful creation of loan!`, { variant: "success" }); //CHNAGE THIS MESSAGE?

      //navigate(`https://crypto-credit.com/thankyou`, { replace: true });
      window.location.href = "https://crypto-credit.com/thankyou";
    }
    setPaymentLoading(false);
    setActiveStep(3);
    await auth.signOut();
  };

  return (
    <>
      <div className="loanApp">
        <h2 className="title">Loan application</h2>
        <p className="text">You are applying for a new loan.</p>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => handleStep(i)}>
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={2} className="loanApp__stepper">
          <Grid item sm={12} md={6}>
            <div className="loanApp__application">
              {activeStep === 0 && (
                <div className="loanApp__step1">
                  <>
                    <FormControl variant="standard">
                      <InputLabel>Loan amount ($USD)</InputLabel>
                      <Input fullWidth type="number" onChange={(e) => setLoanAmount(e.target.value)} value={loanAmount} />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel>Loan cryptocurrency</InputLabel>
                      <Select value={coin} onChange={(e) => setCoin(e.target.value)} fullWidth>
                        <MenuItem value={"BTC"}>Bitcoin ($BTC)</MenuItem>
                        <MenuItem value={"ETH"}>Ethereum ($ETH)</MenuItem>
                        <MenuItem value={"BNB"}>Binance ($BNB)</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="standard">
                      <TextField
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        defaultValue={"12 Weeks"}
                        label="Loan term"
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <TextField
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        defaultValue={"Weekly"}
                        label="Payment frequency"
                      />
                    </FormControl>
                    <button
                      className="button-dark"
                      onClick={() => {
                        if (!loanAmount || !coin || !term || !frequency) {
                          enqueueSnackbar("Please provice all necessary information", {
                            variant: "error",
                          });
                          return;
                        }
                        if (parseInt(loanAmount) < 5) {
                          enqueueSnackbar("The minimum amount for a loan is 50$, please increase your desired loan amount", {
                            variant: "error",
                          });
                          return;
                        }
                        setActiveStep(2);
                      }}
                    >
                      Next
                    </button>
                  </>
                </div>
              )}

              {/* {activeStep === 1 && (
                <div className="loanApp__step2">
                  <div className="loanApp__step2__payment">
                    <p className="text">Due today</p>
                    <h1 className="title">$ {Math.round(parseInt(loanAmount) * 0.13 * 10) / 10}</h1>
                  </div>
                  <h2 className="title">
                    Your payment will be $ {Math.round(parseInt(loanAmount) * 0.13 * 10) / 10} <br /> every week for 12 more weeks
                  </h2>
                  <p className="text">You are applying for a new loan.</p>
                  <button className="button-dark" onClick={() => setOpenContract(!openContract)}>
                    Read contract &nbsp; <HiArrowNarrowRight />
                  </button>
                  <Dialog open={openContract} onClose={() => setOpenContract(false)} className="loanApp__dialog">
                    <>
                      <h3 className="title">Review and sign</h3>
                      <p className="text">
                        <b>Parties</b>
                        <br />
                        This Payment Agreement ( hereinafter referred to as the AGREEMENT ) is entered into on {new Date().toDateString()} (the effective date), by and between {currentUser.firstName}{" "}
                        {currentUser.lastName} ( hereinafter referred to as the DEBTOR), and Crypto Credit ( hereinafter referred to as the CREDITOR) (collectively referred to as the PARTIES)
                        <br />
                        <br />
                        <b>Agreement</b>
                        <br />- The Parties agree that the Debtor is to pay the Creditor a total amount of {loanAmount} <br />- The Agreement is a Layaway Plan for the purchase of approximately{" "}
                        {coinQuantityCalc()} - {coin} <br />- The Parties agree that once the full payment plan is completed, an amount of approximately {coinQuantityCalc()} - {coin} will be made
                        available to the Debtor <br />- The initial payment of approximately {coinQuantityCalc()} - first payment amount {firstPaymentCalc()}$ will be required in order to start the
                        process of the Agreement <br />- Once the initial payment is received, Crypto Credit will purchase and store the agreed amount of approximately {coinQuantityCalc()} - {coin}{" "}
                        and will store it until completion of the Agreement. <br />- The Debtor agrees to pay 12 weekly instalment payments of
                        {firstPaymentCalc()} by the chosen payment method. <br />
                        - This loan carries 0% interest. <br />
                        - There is a storage fee equivalent to 5.307% weekly of the initial amount held in custody. The repayment of the storage fees will be taken in full first and the capital of the
                        loan will follow.
                        <br />
                        - A Mandatory 12 weeks prepaid subscription to the storage services is required in order to obtain a loan. <br />
                        - In the event that the loan is paid off before the 12 weeks term, the subscription fees will still apply for the whole term.
                        <br />
                        - In case of a missed payment, the Debtor will have 48 hours to make the missed payment <br />
                        - Failure to send a missed payment in full to the Creditor, the Debtor will forfeit all the payments already made to the Debtor. The Debtor can chose to void the Agreement at
                        that moment. <br />- In the event that the payment method is a Preauthorized debit, an NSF fee of 50$ will be added to the balance <br />
                        - The Debtor can cancel this contract any time. If the Debtor chooses to do so, he will forfeit all the payments made to the Debtor up to that point. A cancellation is FINAL
                        and irreversible. <br />
                        <br />
                        <b>Default and Cancellation</b>
                        <br />
                        - In case the Debtor fails to provide the payments as per the payment plans within 48 hours of the due date, the Creditor becomes entitled to liquidate the digital assets held
                        in custody without further notice. The Agreement will then be nullified. <br />
                        - The Debtor can cancel this Agreement at anytime by notifying the Creditor via Email. Only after the Debtor receives a confirmation from the Creditor that the Agreement will
                        be cancelled. By cancelling the Agreement, the Creditor forfeits all the digital assets held in custody by the Creditor <br />
                        <br />
                        <b>Governing Law</b>
                        <br />
                        - This Agreement shall be governed by and construed in accordance with the laws of Canada <br />
                        <br />
                        <b>Entire Agreement</b>
                        <br />
                        - This Agreement contains the entire Agreement and understanding among the Parties hereto with respect to the subject matter hereof, and supersedes all prior agreements,
                        understandings, inducements, and condition, express or implied, oral or written, of any nature whatsoever with respect to the subject matter hereof, the express terms hereof
                        control and supersede any course of performance and/or usage of the trade inconsistent with any of the terms hereof.
                        <br />
                        The exact amount of {coin} will be determined by market value at the moment of purchase, immediately following the payment. <br />
                        <br />
                        The Parties hereby agree to the terms and conditions set forth in this Agreement.
                      </p>
                      <p className="text">Signature:</p>
                      <SignatureCanvas backgroundColor="#fff" ref={sigPad} />
                      <div className="canvas"></div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={signContract}
                              onChange={() => {
                                setSignContract(!signContract);
                                setSigPadString(sigPad.current.toDataURL());
                              }}
                            />
                          }
                          label="I agree to the terms and conditions of this loan"
                        />
                      </FormGroup>
                      <button className="button-dark" onClick={() => setActiveStep(2)} disabled={!signContract}>
                        Sign Contract
                      </button>
                    </>
                  </Dialog>
                </div>
              )} */}

              {activeStep === 2 && (
                <>
                  <h3 className="title">Payment methods</h3>
                  {paymentLoading ? (
                    <img src={loadingGif} />
                  ) : (
                    <>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Payment Type</FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" onChange={(event) => setPaymentType(event.target.value)}>
                          <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" />
                          <FormControlLabel value="EFT" control={<Radio />} label="EFT" />
                          <FormControlLabel value="Interac" control={<Radio />} label="Interac" />
                        </RadioGroup>
                      </FormControl>
                      {paymentType === "CreditCard" && (
                        <>
                          <p className="text">Please provide your credit card information. This payment method will be used throughout your loan.</p>
                          <CreditCardPaymentForm
                            cvc={cvc}
                            setCvc={setCvc}
                            expiry={expiry}
                            setExpiry={setExpiry}
                            cardNums={cardNums}
                            setCardNums={setCardNums}
                            cardInputValue={cardInputValue}
                            setCardInputValue={setCardInputValue}
                            name={`${currentUser.firstName}  ${currentUser.lastName}`}
                          />
                        </>
                      )}
                      {paymentType === "EFT" && (
                        <>
                          <p className="text">Please provide your bank account information, often located on a blank cheque. This payment method will be used throughout your loan.</p>
                          <EFTPaymentForm
                            institutionNumber={institutionNumber}
                            setInstitutionNumber={setInstitutionNumber}
                            transitNumber={transitNumber}
                            setTransitNumber={setTransitNumber}
                            accountNumber={accountNumber}
                            setAccountNumber={setAccountNumber}
                          />
                        </>
                      )}
                      <button
                        disabled={paymentLoading}
                        className="button-dark"
                        onClick={() => {
                          setActiveStep(3);
                          handleSumsub();
                        }}
                      >
                        Confirm
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <Box sx={{ width: "500px" }}>
                <div id="sumsub-websdk-container-loan-application"></div>
              </Box>
            </Dialog>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="loanApp__help">
              <h2 className="title">Need Help?</h2>
              <hr className="hr_line" />
              <p className="text">If you need any help or have any questions, you can always contact us!</p>
              <a className="loanApp__help__cta" href="mailto:info@cryptocredit.com">
                <MdEmail />
                info@cryptocredit.com
              </a>
              <a className="loanApp__help__cta" href="/help">
                <AiOutlineQuestionCircle />
                Create a ticket
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoanApplication;
