import React, { ReactNode } from "react";

//Custom Components
import Sidebar from "../Sidebar";

interface Props {
  children: ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="layout__container">
        <Sidebar />
        <div className="layout__page">
          {children}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
