import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import Input from "@mui/material/Input";

interface Props {
  cvc: string;
  setCvc: Dispatch<SetStateAction<string>>;
  expiry: string;
  setExpiry: Dispatch<SetStateAction<string>>;

  name: string;
  cardInputValue: string;
  setCardInputValue: Dispatch<SetStateAction<string>>;
  cardNums: string;
  setCardNums: Dispatch<SetStateAction<string>>;
}

export const CreditCardPaymentForm: React.FC<Props> = ({
  cvc,
  setCvc,
  expiry,
  setExpiry,
  name,
  cardInputValue,
  setCardInputValue,
  cardNums,
  setCardNums,
}) => {
  const [focus, setFocus] = useState<any>("");
  const handleInputFocus = (e: any) => {
    setFocus(e.target.name);
  };

  useEffect(() => {
    return () => {
      console.log("Cleanup");
      setCardNums("");
      setCardInputValue("");
      setExpiry("");
      setCvc("");
    };
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "number": {
        if (value.length > 19) return;
        const cardNumbers = value.replace(new RegExp("-", "g"), "");
        const inputValue = cardNumbers.charAt(value.length - 1);

        if (isNaN(inputValue)) return; //User entered non-numeric value

        setCardNums(cardNumbers);

        //User is deleting card number values
        if (value.length < cardInputValue.length) {
          setCardInputValue(value);
          return;
        }

        if (cardNumbers.length % 4 === 0 && cardNumbers.length < 16)
          setCardInputValue(value.substring(0, value.length) + "-");
        else setCardInputValue(value);
        break;
      }
      case "cvc": {
        if (isNaN(value)) return; //User entered non-numeric value
        setCvc(value);
        break;
      }
      case "expiry": {
        const expiryNum = value.replace(new RegExp("/", "g"), "");

        if (isNaN(expiryNum)) return; //User entered non-numeric value

        //User is deleting card number values
        if (value.length < expiry.length) {
          setExpiry(value);
          return;
        }

        if (expiryNum.length === 2) setExpiry(value + "/");
        else setExpiry(value);

        if (expiryNum) break;
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
      id="PaymentForm"
    >
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focus}
        name={name}
        number={cardNums}
      />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          padding: 10,
        }}
      >
        <Input
          fullWidth
          type="tel"
          name="number"
          placeholder="Card Number"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          value={cardInputValue}
        />
        <Input
          fullWidth
          name="cvc"
          placeholder="CVC"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          value={cvc}
        />
        <Input
          type="string"
          fullWidth
          name="expiry"
          placeholder="Expiry (MM/YY)"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          value={expiry}
        />
      </form>
    </div>
  );
};
