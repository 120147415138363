import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
//Custom components
import { useFetchUserSingleton } from "./useFetchUser";
// SumSub
import snsWebSdk from "@sumsub/websdk";
// Firebase
import { firestore, functions } from "../firebase/firebase";
import { httpsCallable } from "firebase/functions";

const useVerifySumsub = (setOpenDialog: Dispatch<SetStateAction<boolean>>, firebaseUserId: string, setIsApplicantSubmitted?: Dispatch<SetStateAction<boolean>>) => {
  const { currentUser } = useFetchUserSingleton();
  const location = useLocation();
  const handleSumsub = async () => {
    setOpenDialog(true);
    await getToken();
  };

  const getToken = async () => {
    launchWebSdk(await fetchToken(), setIsApplicantSubmitted);
  };

  const fetchToken = async () => {
    const sumsub = httpsCallable(functions, "sumsub-sumsubKYC");
    const data = JSON.parse(((await sumsub(JSON.stringify({ id: firebaseUserId }))) as any).data);
    return data.token;
  };

  async function launchWebSdk(accessToken: string, setIsApplicantSubmitted?: Dispatch<SetStateAction<boolean>>) {
    let snsWebSdkInstance = snsWebSdk
      .init(
        accessToken,
        // token update callback, must return Promise
        fetchToken
      )
      .withBaseUrl("https://api.sumsub.com")
      .withConf({
        lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
      })
      .on("idCheck.onReady", (payload) => {
        console.log("ready", payload);
      })
      .on("idCheck.stepCompleted", (payload) => {
        console.log("stepCompleted", payload);
      })
      .on("idCheck.applicantStatus", (payload) => {
        console.log("applicantStatus", payload);
      })
      .on("idCheck.onError", (error) => {
        console.log("onError", error);
      })
      .on("idCheck.onApplicantSubmitted", (payload) => {
        if (setIsApplicantSubmitted) setIsApplicantSubmitted(true);
      })
      .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    if (location.pathname === "/loans") snsWebSdkInstance.launch("#sumsub-websdk-container-loans");
    else if (location.pathname === "/account") snsWebSdkInstance.launch("#sumsub-websdk-container-account");
    else if (location.pathname === "/loan-application") snsWebSdkInstance.launch("#sumsub-websdk-container-loan-application");
  }

  return { handleSumsub };
};

export default useVerifySumsub;
