import { Dispatch, SetStateAction, useEffect } from "react";
import Input from "@mui/material/Input";

interface Props {
  institutionNumber: string;
  setInstitutionNumber: Dispatch<SetStateAction<string>>;
  transitNumber: string;
  setTransitNumber: Dispatch<SetStateAction<string>>;
  accountNumber: string;
  setAccountNumber: Dispatch<SetStateAction<string>>;
}

export const EFTPaymentForm: React.FC<Props> = ({
  institutionNumber,
  setInstitutionNumber,
  transitNumber,
  setTransitNumber,
  accountNumber,
  setAccountNumber,
}) => {
  useEffect(() => {
    return () => {
      console.log("Cleanup");
      setInstitutionNumber("");
      setTransitNumber("");
      setAccountNumber("");
    };
  }, []);
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "institutionNumber": {
        setInstitutionNumber(value);
        break;
      }
      case "transitNumber": {
        setTransitNumber(value);
        break;
      }
      case "accountNumber": {
        setAccountNumber(value);
        return;
      }
    }
  };
  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        padding: 10,
      }}
    >
      <Input
        fullWidth
        type="tel"
        name="institutionNumber"
        placeholder="Institution Number"
        onChange={handleInputChange}
        value={institutionNumber}
      />
      <Input
        fullWidth
        name="transitNumber"
        placeholder="Transit Number"
        onChange={handleInputChange}
        value={transitNumber}
      />
      <Input
        type="string"
        fullWidth
        name="accountNumber"
        placeholder="Account Number"
        onChange={handleInputChange}
        value={accountNumber}
      />
    </form>
  );
};
